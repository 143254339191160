import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgent {
  id: IdType,
  email: string,
  fullname: string,
  phoneMobile: string | null,
}

export const AgentFields: ReadonlyNodeFields = {
  id: true,
  email: true,
  fullname: true,
  phoneMobile: true,
};

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
  phone: string | null,
  fullAddress: string,
  users: Array<MyBreteuilAgent>,
}

export const AgencyFields: ReadonlyNodeFields = {
  id: true,
  name: true,
  phone: true,
  fullAddress: true,
  users: paginationResultAsList(AgentFields),
};


export interface Transaction
{
  id: IdType,
  price: number,
  pricePerSurface: number,
  surface: number,
  floor: number | null,
  constructionYear: number | null,
  comment: string | null,
  date: number/** timestamp */,
  address: string,
  latitude: number,
  longitude: number,
  postalCode: string,
  city: string,
}

export const TransactionFields: ReadonlyNodeFields = {
  id: true,
  price: true,
  pricePerSurface: true,
  surface: true,
  floor: true,
  constructionYear: true,
  comment: true,
  date: true,
  address: true,
  latitude: true,
  longitude: true,
  postalCode: true,
  city: true,
};


export interface PropertyValuationPDFData
{
  language: Language,
  descriptionLanguage: Language,
  description: string,
  pros: Array<string>,
  cons: Array<string>,
  feesPercentage: number,
  feesSeller?: boolean | undefined,
  transactionsAreaId: IdType,
  transactionsIds: Array<IdType>,
  additionalTransactions: Array<{
    pricePerSurface: number,
    surface: number,
    floor: number | null,
    constructionYear: number | null,
    date: Date/** timestamp */, // unsure about Date type
    address: string,
    resolvedAddress: string,
    latitude: number,
    longitude: number,
    postalCode: string,
  }> | null,
  mapCenterLat?: number | null | undefined,
  mapCenterLng?: number | null | undefined,
  mapZoomLevel?: number | null | undefined,
  displayedPrice_FAI: number | null,
  valuationPrice_FAI: number | null,
  districtPictureURL: string | null,
  highlightedProperties: Array<{ ref: string, pictureNum: number }>,
  graphAreaIds: Array<IdType>,
  rentalFurnishedDisplayedPrice: number | null,
  rentalFurnishedValuationPrice: number | null,
  rentalDisplayedPrice: number | null,
  rentalValuationPrice: number | null,
  displayFurnishedPrice: boolean,
  displayPrice: boolean,
  agencyId: IdType | null,
  agentId: IdType | null,
  transactionsFilters?: {
    surfaceMin?: number | null | undefined,
    surfaceMax?: number | null | undefined,
    radius?: number | null | undefined,
    sameStreet?: boolean | null | undefined,
    streetNumberOdd?: boolean | null | undefined,
    dateMin?: number | null | undefined,
    dateMax?: number | null | undefined,
    pricePerSurfaceMin?: number | null | undefined,
    pricePerSurfaceMax?: number | null | undefined,
  } | undefined,
  displayLastModificationDate?: boolean | null | undefined,
  transactionDisplayRentalSlide?: boolean | null | undefined,
}

export const PropertyValuationPDFDataFields = {
  language: true,
  descriptionLanguage: true,
  description: true,
  pros: true,
  cons: true,
  feesPercentage: true,
  feesSeller: true,
  transactionsAreaId: true,
  transactionsIds: true,
  additionalTransactions: {
    pricePerSurface: true,
    surface: true,
    floor: true,
    constructionYear: true,
    date: true,
    address: true,
    resolvedAddress: true,
    latitude: true,
    longitude: true,
    postalCode: true,
  },
  mapCenterLat: true,
  mapCenterLng: true,
  mapZoomLevel: true,
  displayedPrice_FAI: true,
  valuationPrice_FAI: true,
  districtPictureURL: true,
  highlightedProperties: {
    ref: true,
    pictureNum: true,
  },
  graphAreaIds: true,
  rentalFurnishedDisplayedPrice: true,
  rentalFurnishedValuationPrice: true,
  rentalDisplayedPrice: true,
  rentalValuationPrice: true,
  displayFurnishedPrice: true,
  displayPrice: true,
  agencyId: true,
  agentId: true,
  transactionsFilters: {
    surfaceMin: true,
    surfaceMax: true,
    radius: true,
    sameStreet: true,
    streetNumberOdd: true,
    dateMin: true,
    dateMax: true,
    pricePerSurfaceMin: true,
    pricePerSurfaceMax: true,
  },
  displayLastModificationDate: true,
  transactionDisplayRentalSlide: true,
} as const satisfies ReadonlyNodeFields;

export interface MyBreteuilPropertyValuationPDF
{
  id: IdType,
  data: PropertyValuationPDFData,
  lastModificationDate: Date,
  validationRequested: boolean,
  pending: boolean,
  accepted: boolean,
  agent: MyBreteuilAgent | null,
  agency: MyBreteuilAgency | null,
  transactions: Array<Transaction>,
  valuation: {
    id: IdType,
    date: number,
  } | null,
  currentUserCanForceValidation: boolean,
  validationDeniedComment: string | null,
  validationDeniedUser: {
    id: IdType,
    fullname: string,
  },
}

export const MyBreteuilPropertyValuationPDFFields = {
  id: true,
  data: PropertyValuationPDFDataFields,
  lastModificationDate: true,
  validationRequested: true,
  pending: true,
  accepted: true,
  agent: AgentFields,
  agency: AgencyFields,
  transactions: TransactionFields,
  valuation: {
    id: true,
    date: true,
  },
  currentUserCanForceValidation: true,
  validationDeniedComment: true,
  validationDeniedUser: {
    id: true,
    fullname: true,
  },
} as const satisfies ReadonlyNodeFields;
