import type { IdType } from '@repo-breteuil/common-definitions';
import { mapRecord } from '@repo-lib/utils-core';
import type { ReadonlyNode, ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type {
  BreteuilPropertyLawFulRental,
  BreteuilPropertySurfaceUnit,
  BreteuilPropertyUsage,
  BreteuilWebsiteRenovationType,
  BreteuilWebsiteViewType,
  BuildingType,
  DPERating,
  EntityLogsEventType,
  MyBreteuilPropertyDisponibilityEnum,
  MyBreteuilPropertyRentalExpensesTypeEnum,
  MyBreteuilPropertyVisibility,
  MyBreteuilWaterHeating,
  OperationType,
  PropertyStatus,
  PropertyType,
  Filters__Date, MyBreteuilHeating,
  MyBreteuilPropertySurroundingDistance,
  TransactionStep,
  CommunicationChannelEnum,
  HistoryEventCommunicationType,
  MyBreteuilContactCategory,
  AlertsFrequency,
} from '@core/api/types';

export interface UpdateDiff<T>
{
  from: T | null,
  to: T | null,
}

function makeUpdateDiffFields(node: ReadonlyNode): ReadonlyNodeFields
{
  return {
    from: node,
    to: node,
  };
}

type WrapUpdateDiffObj<T> = {
  [K in keyof T]: UpdateDiff<T[K]> | null;
};

export interface HistoryEventDataMyBreteuilPropertyVersion
{
  title: string,
  price: number,
  priceMax: number,
  priceOnRequest: boolean,
  operationType: OperationType,
  status: PropertyStatus,
  displayedStatus: PropertyStatus,
  availabilityDate: number,
  agency: {
    id: IdType,
    shortName: string,
  },
  warrantNumber: string,
  warrantDate: number,
  warrantEndDate: number,
  exclusive: boolean,
  verifiedWarrant: boolean,
  addrStreetAddress: string,
  geoArea: {
    id: IdType,
    shortName: string,
  },
  users: Array<{
    id: IdType,
    fullname: string,
  }>,
  origine: string,
  contacts: Array<{
    id: IdType,
    fullname: string,
  }>,
  companyManagementBroughtDeal: boolean,
  marketConsistent: boolean,
  descriptionFR: string,
  descriptionEN: string,
  descriptionPT: string,
  descriptionES: string,
  mybDescriptionSmsFR: string,
  mybDescriptionSmsEN: string,
  mybDescriptionSmsPT: string,
  mybDescriptionSmsES: string,
  quoteDescriptionFR: string,
  quoteDescriptionEN: string,
  quoteDescriptionPT: string,
  quoteDescriptionES: string,
  practicalInformations: string,
  collectiveOwnership: boolean,
  lots: boolean,
  collectiveOwnershipCharges: number,
  mybTaxeFonciere: number,
  rentalExpenses: number,
  rentalExpensesType: MyBreteuilPropertyRentalExpensesTypeEnum,
  rentalDeposit: number,
  mybLocatifLegal: BreteuilPropertyLawFulRental,
  fees: number,
  feesPercentage: number,
  feesPaidByBuyer: boolean,
  rentalOwnerIntermediary: number,
  rentalAdministrationFees: number,
  rentalOwnerAdministrationFees: number,
  rentalInventory: number,
  rentalOwnerInventory: number,
  guestsCount: number,
  keyInfos: Array<{
    id: IdType,
    content: string,
  }>,
  surroundings: Array<{
    id: IdType,
    distance: MyBreteuilPropertySurroundingDistance,
    surrounding: {
      id: IdType,
      content: string,
    },
  }>,
  surface: number,
  surfaceUnit: BreteuilPropertySurfaceUnit,
  bedrooms: number,
  floor: number,
  floorsCount: number,
  propertyType: PropertyType,
  constructionYear: number,
  criteria: Array<{
    id: IdType,
    webName: string,
  }>,
  lotSize: number,
  rooms: number,
  bathrooms: number,
  toilets: number,
  balconyCount: number,
  balconySurface: number,
  ceilingHeight: number,
  heating: MyBreteuilHeating,
  waterHeatType: MyBreteuilWaterHeating,
  usage: BreteuilPropertyUsage,
  disponibility:MyBreteuilPropertyDisponibilityEnum,
  orientation: OrientationType,
  sectorization: string,
  transports: string,
  buildingType: BuildingType,
  dpe: number,
  dpeCarbonFootprint: number,
  dpeKwhRatingOverride: DPERating,
  dpeCo2RatingOverride: DPERating,
  dpeCreationDate: number,
  dpeSimulationReferenceDate: number,
  dpeAnnualCost: number,
  inflatedRent: number,
  rentControl: boolean,
  publication: MyBreteuilPropertyVisibility,
  publicationPlatformAgency: {
    id: IdType,
    shortName: string,
  },
  forceSendable: boolean,
  photographer: {
    id: IdType,
    name: string,
  },
  videoURL: string,
  view: BreteuilWebsiteViewType,
  renovation: BreteuilWebsiteRenovationType,
  addressPricePerSurfaceDifferenceFromSubDistrict: number,
}

export type HistoryEventDataMyBreteuilPropertyUpdateDiff = WrapUpdateDiffObj<HistoryEventDataMyBreteuilPropertyVersion>;

const HistoryEventDataMyBreteuilPropertyVersionFields: ReadonlyNodeFields = {
  title: true,
  price: true,
  priceMax: true,
  priceOnRequest: true,
  operationType: true,
  status: true,
  displayedStatus: true,
  availabilityDate: true,
  agency: {
    id: true,
    shortName: true,
  },
  warrantNumber: true,
  warrantDate: true,
  warrantEndDate: true,
  exclusive: true,
  verifiedWarrant: true,
  addrStreetAddress: true,
  geoArea: {
    id: true,
    shortName: [{ args: { language: true } }, true],
  },
  users: {
    user: {
      id: true,
      fullname: true,
    },
  },
  origine: true,
  contacts: {
    id: true,
    fullname: true,
  },
  companyManagementBroughtDeal: true,
  marketConsistent: true,
  descriptionFR: true,
  descriptionEN: true,
  descriptionPT: true,
  descriptionES: true,
  mybDescriptionSmsFR: true,
  mybDescriptionSmsEN: true,
  mybDescriptionSmsPT: true,
  mybDescriptionSmsES: true,
  quoteDescriptionFR: true,
  quoteDescriptionEN: true,
  quoteDescriptionPT: true,
  quoteDescriptionES: true,
  practicalInformations: true,
  collectiveOwnership: true,
  lots: true,
  collectiveOwnershipCharges: true,
  mybTaxeFonciere: true,
  rentalExpenses: true,
  rentalExpensesType: true,
  rentalDeposit: true,
  mybLocatifLegal: true,
  fees: true,
  feesPercentage: true,
  feesPaidByBuyer: true,
  rentalOwnerIntermediary: true,
  rentalAdministrationFees: true,
  rentalOwnerAdministrationFees: true,
  rentalInventory: true,
  rentalOwnerInventory: true,
  guestsCount: true,
  keyInfos: {
    id: true,
    content: [{ args: { language: true } }, true],
  },
  surroundings: {
    id: true,
    distance: true,
    surrounding: {
      id: true,
      content: [{ args: { language: true } }, true],
    },
  },
  surface: true,
  surfaceUnit: true,
  bedrooms: true,
  floor: true,
  floorsCount: true,
  propertyType: true,
  constructionYear: true,
  criteria: {
    id: true,
    webName: [{ args: { language: true } }, true],
  },
  lotSize: true,
  rooms: true,
  bathrooms: true,
  toilets: true,
  balconyCount: true,
  balconySurface: true,
  ceilingHeight: true,
  heating: true,
  waterHeatType: true,
  usage: true,
  disponibility:true,
  orientation: true,
  sectorization: true,
  transports: true,
  buildingType: true,
  dpe: true,
  dpeCarbonFootprint: true,
  dpeKwhRatingOverride: true,
  dpeCo2RatingOverride: true,
  dpeCreationDate: true,
  dpeSimulationReferenceDate: true,
  dpeAnnualCost: true,
  inflatedRent: true,
  rentControl: true,
  publication: true,
  publicationPlatformAgency: {
    id: true,
    shortName: true,
  },
  forceSendable: true,
  photographer: {
    id: true,
    name: true,
  },
  videoURL: true,
  view: true,
  renovation: true,
  addressPricePerSurfaceDifferenceFromSubDistrict: true,
};

const HistoryEventDataMyBreteuilPropertyUpdateDiffFields = (
  mapRecord(HistoryEventDataMyBreteuilPropertyVersionFields, (node) => makeUpdateDiffFields(node))
);

export interface HistoryEventDataPropertyUpdate
{
  updateDiff: HistoryEventDataMyBreteuilPropertyUpdateDiff,
  updatedFields: Array<string>,
  __typename: 'HistoryEventFormattedData_MyBreteuilProperty_Update',
}

const HistoryEventDataPropertyUpdateFields: ReadonlyNodeFields = {
  updateDiff: HistoryEventDataMyBreteuilPropertyUpdateDiffFields,
  updatedFields: true,
};

export interface HistoryEventDataPropertyCreate
{
  version: HistoryEventDataMyBreteuilPropertyVersion,
  __typename: 'HistoryEventFormattedData_MyBreteuilProperty_Create',
}

const HistoryEventDataPropertyCreateFields: ReadonlyNodeFields = {
  version: HistoryEventDataMyBreteuilPropertyVersionFields,
};


export interface HistoryEventDataPropertyDelete
{
  version: HistoryEventDataMyBreteuilPropertyVersion,
  __typename: 'HistoryEventFormattedData_MyBreteuilProperty_Delete',
}

const HistoryEventDataPropertyDeleteFields: ReadonlyNodeFields = {
  version: HistoryEventDataMyBreteuilPropertyVersionFields,
};

export interface HistoryEventFormattedDataViewingCreate
{
  viewing: {
    id: IdType,
    date: number,
    contact: {
      id: IdType,
      fullname: string,
    } | null,
  } | null,
  __typename: 'HistoryEventFormattedData_Viewing_Create',
}

const HistoryEventFormattedDataViewingCreateFields: ReadonlyNodeFields = {
  viewing: {
    id: true,
    date: true,
    contact: {
      id: true,
      fullname: true,
    },
  },
};

export interface HistoryEventFormattedData_Transaction_Create
{
  transaction: {
    id: IdType,
    sellerFullName: string,
  } | null,
  step: TransactionStep | null,
  __typename: 'HistoryEventFormattedData_Transaction_Create',
}

const HistoryEventFormattedData_Transaction_CreateFields: ReadonlyNodeFields = {
  transaction: {
    id: true,
    sellerFullName: true,
  },
  step: true,
};

export interface HistoryEventFormattedData_CommunicationSend
{
  contact: {
    id: IdType,
    fullname: string,
  } | null,
  __typename: 'HistoryEventFormattedData_CommunicationSend',
}

const HistoryEventFormattedData_CommunicationSendFields: ReadonlyNodeFields = {
  contact: {
    id: true,
    fullname: true,
  },
};

// Contact

export interface HistoryEventDataContactVersion
{
  persons: Array<{
    firstname: string,
    lastname: string,
    emails: Array<string>,
    phones: Array<string>,
  }>,
  optIn: boolean,
  newsletterOptIn: boolean,
  category: MyBreteuilContactCategory,
  alertsFrequency: AlertsFrequency,
}

const HistoryEventDataContactVersionFields: ReadonlyNodeFields = {
  persons: {
    firstname: true,
    lastname: true,
    emails: true,
    phones: true,
  },
  optIn: true,
  newsletterOptIn: true,
  category: true,
  alertsFrequency: true,
};

export type HistoryEventDataContactVersionUpdateDiff = WrapUpdateDiffObj<HistoryEventDataContactVersion>;

const HistoryEventDataContactVersionUpdateDiffFields = (
  mapRecord(HistoryEventDataContactVersionFields, (node) => makeUpdateDiffFields(node))
);

export interface HistoryEventFormattedData_MyBreteuilContact_Create
{
  version: HistoryEventDataContactVersion,
  __typename: 'HistoryEventFormattedData_MyBreteuilContact_Create',
}

const HistoryEventFormattedData_MyBreteuilContact_CreateFields: ReadonlyNodeFields = {
  version: HistoryEventDataContactVersionFields,
};

export interface HistoryEventFormattedData_MyBreteuilContact_Update
{
  updateDiff: HistoryEventDataContactVersionUpdateDiff,
  updatedFields: Array<string>,
  __typename: 'HistoryEventFormattedData_MyBreteuilContact_Update',
}

const HistoryEventFormattedData_MyBreteuilContact_UpdateFields: ReadonlyNodeFields = {
  updateDiff: HistoryEventDataContactVersionUpdateDiffFields,
  updatedFields: true,
};

export interface HistoryEventFormattedData_MyBreteuilContact_Delete
{
  version: HistoryEventDataContactVersion,
  __typename: 'HistoryEventFormattedData_MyBreteuilContact_Delete',
}

const HistoryEventFormattedData_MyBreteuilContact_DeleteFields: ReadonlyNodeFields = {
  version: HistoryEventDataContactVersionFields,
};

// Search

export interface HistoryEventDataSearchVersion
{
  archived: boolean,
  budgetMin: number,
  budgetMax: number,
  surfaceMin: number,
  surfaceMax: number,
  bedroomsMin: number,
  bedroomsMax: number,
  operationType: OperationType,
  areas: Array<{
    id: IdType,
    shortName: string,
  }>,
  criteria: Array<{
    id: IdType,
    webName: string,
  }>,
  users: Array<{
    id: IdType,
    fullname: string,
  }>,
}

const HistoryEventDataSearchVersionFields: ReadonlyNodeFields = {
  archived: true,
  budgetMin: true,
  budgetMax: true,
  surfaceMin: true,
  surfaceMax: true,
  bedroomsMin: true,
  bedroomsMax: true,
  operationType: true,
  areas: {
    id: true,
    shortName: [{ args: { language: true } }, true],
  },
  criteria: {
    id: true,
    webName: [{ args: { language: true } }, true],
  },
  users: {
    id: true,
    fullname: true,
  },
};

export type HistoryEventDataSearchVersionUpdateDiff = WrapUpdateDiffObj<HistoryEventDataSearchVersion>;

const HistoryEventDataSearchVersionUpdateDiffFields = (
  mapRecord(HistoryEventDataSearchVersionFields, (node) => makeUpdateDiffFields(node))
);

export interface HistoryEventFormattedData_MyBreteuilSearch_Create
{
  version: HistoryEventDataSearchVersion,
  __typename: 'HistoryEventFormattedData_MyBreteuilSearch_Create',
}

const HistoryEventFormattedData_MyBreteuilSearch_CreateFields: ReadonlyNodeFields = {
  version: HistoryEventDataSearchVersionFields,
};

export interface HistoryEventFormattedData_MyBreteuilSearch_Update
{
  updateDiff: HistoryEventDataSearchVersionUpdateDiff,
  updatedFields: Array<string>,
  __typename: 'HistoryEventFormattedData_MyBreteuilSearch_Update',
}

const HistoryEventFormattedData_MyBreteuilSearch_UpdateFields: ReadonlyNodeFields = {
  updateDiff: HistoryEventDataSearchVersionUpdateDiffFields,
  updatedFields: true,
};

export interface HistoryEventFormattedData_MyBreteuilSearch_Delete
{
  version: HistoryEventDataSearchVersion,
  __typename: 'HistoryEventFormattedData_MyBreteuilSearch_Delete',
}

const HistoryEventFormattedData_MyBreteuilSearch_DeleteFields: ReadonlyNodeFields = {
  version: HistoryEventDataSearchVersionFields,
};

export type HistoryEventFormattedData
  = HistoryEventDataPropertyCreate
  | HistoryEventDataPropertyUpdate
  | HistoryEventDataPropertyDelete
  | HistoryEventFormattedDataViewingCreate
  | HistoryEventFormattedData_Transaction_Create
  | HistoryEventFormattedData_CommunicationSend
  | HistoryEventFormattedData_MyBreteuilContact_Create
  | HistoryEventFormattedData_MyBreteuilContact_Update
  | HistoryEventFormattedData_MyBreteuilContact_Delete
  | HistoryEventFormattedData_MyBreteuilSearch_Create
  | HistoryEventFormattedData_MyBreteuilSearch_Update
  | HistoryEventFormattedData_MyBreteuilSearch_Delete
;

const HistoryEventFormattedDataFields: ReadonlyNode = [{
  polymorphicFields: {
    HistoryEventFormattedData_MyBreteuilProperty_Create: HistoryEventDataPropertyCreateFields,
    HistoryEventFormattedData_MyBreteuilProperty_Update: HistoryEventDataPropertyUpdateFields,
    HistoryEventFormattedData_MyBreteuilProperty_Delete: HistoryEventDataPropertyDeleteFields,
    HistoryEventFormattedData_Viewing_Create: HistoryEventFormattedDataViewingCreateFields,
    HistoryEventFormattedData_Transaction_Create: HistoryEventFormattedData_Transaction_CreateFields,
    HistoryEventFormattedData_CommunicationSend: HistoryEventFormattedData_CommunicationSendFields,
    HistoryEventFormattedData_MyBreteuilContact_Create: HistoryEventFormattedData_MyBreteuilContact_CreateFields,
    HistoryEventFormattedData_MyBreteuilContact_Update: HistoryEventFormattedData_MyBreteuilContact_UpdateFields,
    HistoryEventFormattedData_MyBreteuilContact_Delete: HistoryEventFormattedData_MyBreteuilContact_DeleteFields,
    HistoryEventFormattedData_MyBreteuilSearch_Create: HistoryEventFormattedData_MyBreteuilSearch_CreateFields,
    HistoryEventFormattedData_MyBreteuilSearch_Update: HistoryEventFormattedData_MyBreteuilSearch_UpdateFields,
    HistoryEventFormattedData_MyBreteuilSearch_Delete: HistoryEventFormattedData_MyBreteuilSearch_DeleteFields,
  },
}, {}];

export interface HistoryEvent
{
  id: IdType,
  author: {
    auto: boolean,
    __typename: 'HistoryEventAuthor_Auto',
  } | {
    user: {
      id: IdType,
      fullname: string,
    },
    originUser: {
      id: IdType,
      fullname: string,
    },
    __typename: 'HistoryEventAuthor_Agent',
  } | {
    id: IdType,
    fullname: string,
    __typename: 'MyBreteuilContact',
  } | null,
  target: {
    slug: string,
    __typename: 'MyBreteuilProperty',
  } | {
    id: IdType,
    __typename: 'MyBreteuilContact',
  } | {
    id: IdType,
    contactId: IdType,
    __typename: 'MyBreteuilSearch',
  } | null,
  formattedData: HistoryEventFormattedData | null,
  communicationType: HistoryEventCommunicationType | null,
  communicationChannel: CommunicationChannelEnum | null,
  communicationTarget: string | null,
  createdAt: number,
}

const HistoryEventFields: ReadonlyNodeFields = {
  id: true,
  author: [{
    polymorphicFields: {
      HistoryEventAuthor_Auto: { auto: true },
      HistoryEventAuthor_Agent: {
        user: {
          id: true,
          fullname: true,
        },
        originUser: {
          id: true,
          fullname: true,
        },
      },
      MyBreteuilContact: {
        id: true,
        fullname: true,
      },
    },
  }, {}],
  target: [{
    polymorphicFields: {
      MyBreteuilProperty: {
        slug: true,
      },
      MyBreteuilContact: {
        id: true,
      },
      MyBreteuilSearch: {
        id: true,
        contactId: true,
      },
    },
  }, {}],
  formattedData: HistoryEventFormattedDataFields,
  communicationType: true,
  communicationChannel: true,
  communicationTarget: true,
  createdAt: true,
};

export interface EntityLog
{
  event: HistoryEvent,
  types: Array<EntityLogsEventType>,
}

export const EntityLogFields: ReadonlyNodeFields = {
  event: HistoryEventFields,
  types: true,
};

export interface EntityLogHistoryEventFilters
{
  logTypes?: Array<EntityLogsEventType> | undefined,
  userId?: Array<IdType> | undefined,
  createdAt?: Filters__Date | undefined,
}

/** A simpler version of the API logs filters */
export interface SimpleLogsFilters
{
  types?: Array<EntityLogsEventType> | undefined,
  userId?: Array<IdType> | undefined,
  createdAt?: { min: Date | null, max: Date | null } | undefined,
}

/** Used to convert logs simple filters to API filters */
export function convertSimpleLogsFilters(args: SimpleLogsFilters): { filter: EntityLogHistoryEventFilters }
{
  return {
    filter: {
      logTypes: args.types,
      userId: args.userId,
      createdAt: {
        gte: args.createdAt?.min?.valueOf(),
        lt: args.createdAt?.max?.valueOf(),
      },
    },
  };
}
