import type { IdType } from '@repo-breteuil/common-definitions';

import { action, computed, observable, runInAction } from 'mobx';
import { avg, indexArrayItems, indexMultipleArrayItems, isNonNull } from '@repo-lib/utils-core';
import { createDate, startOfYear } from '@repo-lib/utils-date-tz';
import {
  getMarketDataInfo,
  indexMarketData,
  getAreaLastPricePerSurface,
  getMarketDataIndexed,
  getSelectedAreaPricePerSurface,
  getPricePerSurfaceActualized,
  parsePropertyRefId,
} from '@repo-breteuil/react-valuation-pdf';
import { OrderType } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { assert, ensureFetchableResource, handleNonCriticalError } from '@repo-breteuil/front-error';
import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import localesStore from '@core/store/Locales';
import { applyTablePagination } from '@my-breteuil/store/ui/common/array-processing-utils';
import sessionStore from '@my-breteuil/store/ui/common/Session';
import {
  GetProperty,
  GetHighlightedProperties,
  GetMarketDataPricePerSurfacePerQuarterPerArea,
  GetTransactions,
  UpdateTransaction, type UpdateTransactionArgs,
  GetAgencies,
  GetPropertyUserValuationPDF,
  SetPropertyValuationPDF, type SetPropertyValuationPDFArgs,
  OverwriteLastPropertyValuation,
  RequestValuationPDFValidation,
  ForceValuationPDFValidation,
  Valuation_GetUser,
  Valuation_GetImprovedPropertyDescriptions,
  Valuation_GetFixedPropertyDescription,
  Valuation_GetAnalyzedPropertyDescription,
  Valuation_GetTranslatedPropertyDescription,
} from './api';

export class HighlightedPropertyRefError extends Error
{
  constructor(ref: string)
  {
    super(`Invalid ref: ${ref}`);
    this.name = 'HighlightedPropertyRefError';
  }
}

class ValuationStore
{
  @observable private _preview = false;

  get preview()
  {
    return this._preview;
  }

  @action setPreview(newPreview: boolean)
  {
    this._preview = newPreview;
  }

  private _agencies = new Fetchable(GetAgencies, { catchUnhandled: handleNonCriticalError });

  @computed get agencies()
  {
    return ensureFetchableResource(this._agencies);
  }

  private _user = new Fetchable(Valuation_GetUser, { catchUnhandled: handleNonCriticalError });

  public get userFetchable()
  {
    return this._user;
  }

  @computed get user()
  {
    return ensureFetchableResource(this._user);
  }

  private _property = new Fetchable(GetProperty, { catchUnhandled: handleNonCriticalError });

  get propertyFetchable()
  {
    return this._property;
  }

  @computed get property()
  {
    return ensureFetchableResource(this._property);
  }

  public highlightedProperties = new Fetchable(async (refs: Array<{ ref: string }>) => {
    const validRefs = refs.filter(({ ref }) => ref && ref.trim() !== '');
    if (validRefs.length === 0) {
      return [];
    }
    const refIds = validRefs.map(({ ref }) => {
      const refId = parsePropertyRefId(ref);
      if (!refId)
        throw new HighlightedPropertyRefError(ref);
      return refId;
    });
    const properties = await GetHighlightedProperties(refIds);
    const propertiesByRefId = indexArrayItems(properties, ({ refId }) => refId);
    return refIds.map((refId) => propertiesByRefId.get(refId) ?? null).filter(isNonNull);
  }, { catchUnhandled: handleNonCriticalError });

  @computed public get highlightedPropertiesIndexedByRefId()
  {
    return indexArrayItems(this.highlightedProperties.lastResult ?? [], (property) => property.refId);
  }

  public formatSelectedHighlightedProperties(value: Array<{ ref: string, pictureNum: number | null }>)
  {
    return value.map(({ ref, pictureNum }) => {
      if (pictureNum === null)
        return null;
      const refId = parsePropertyRefId(ref);
      if (refId === null)
        return null;
      const property = this.highlightedPropertiesIndexedByRefId.get(refId);
      if (!property)
        return null;
      const pictureIdx = pictureNum - 1;
      if (pictureIdx >= property.picturesURL.length || pictureIdx < 0)
        return null;
      const pictureURL = property.picturesURL[pictureIdx];
      return { ...property, pictureURL };
    }).filter(isNonNull);
  }

  private _transactionsFilters: {
    surfaceMin?: number | undefined,
    surfaceMax?: number | undefined,
    radius?: number | undefined,
    sameStreet?: boolean | undefined,
    streetNumberOdd?: boolean | undefined,
    dateMin?: number | null | undefined,
    dateMax?: number | null | undefined,
    pricePerSurfaceMin?: number | null | undefined,
    pricePerSurfaceMax?: number | null | undefined,
  } = {};

  get transactionsFilters()
  {
    return this._transactionsFilters;
  }

  public async setTransactionsFiltersAndReload(filters: typeof this._transactionsFilters)
  {
    this._transactionsFilters = filters;
    await this.transactions.ensureSuccessReload();
  }

  public transactions = new Fetchable(() => {
    const {
      surfaceMin,
      surfaceMax,
      sameStreet,
      streetNumberOdd,
      radius,
      dateMin,
      dateMax,
      pricePerSurfaceMin,
      pricePerSurfaceMax,
    } = this._transactionsFilters;
    this.setTransactionsPage(0);
    return GetTransactions({
      first: 1000,
      filter: {
        surface: {
          gte: surfaceMin,
          lte: surfaceMax,
        },
        streetName: sameStreet ? { contains: this.property.addrStreetName } : undefined,
        streetNumberOdd: streetNumberOdd !== undefined ? { equals: streetNumberOdd } : undefined,
        date: {
          gte: dateMin ?? undefined,
          lt: dateMax ?? undefined,
        },
        pricePerSurface: {
          gte: pricePerSurfaceMin ?? undefined,
          lt: pricePerSurfaceMax ?? undefined,
        },
      },
      radiusFilter: radius !== undefined ? {
        center: { latitude: this.property.latitude, longitude: this.property.longitude },
        radius,
      } : undefined,
      orderBy: [{ fieldName: 'date', ordering: OrderType.DESC }],
    });
  }, { catchUnhandled: handleNonCriticalError });

  @computed get indexedTransactions()
  {
    return indexArrayItems(this.transactions.lastResult?.data ?? [], (item) => item.id);
  }

  @observable private _transactionsPage = 0;
  @observable private _transactionsRowsPerPage = 100;

  get transactionsPage()
  {
    return this._transactionsPage;
  }

  @action setTransactionsPage(page: number)
  {
    this._transactionsPage = page;
  }

  get transactionsRowsPerPage()
  {
    return this._transactionsRowsPerPage;
  }

  @computed get paginatedTransactions()
  {
    const transactions = this.transactions.lastResult?.data ?? [];
    return applyTablePagination(transactions, {
      page: this.transactionsPage,
      rowsPerPage: this.transactionsRowsPerPage,
    });
  }

  public async updateTransactions(args: UpdateTransactionArgs)
  {
    const updatedTransaction = await UpdateTransaction(args);
    runInAction(() => {
      const transaction = this.transactions.result?.data.find(transac => transac.id === updatedTransaction.id);
      assert(transaction); // Should not happen
      Object.assign(transaction, updatedTransaction);
    });
  }

  private _marketData = new Fetchable(GetMarketDataPricePerSurfacePerQuarterPerArea, { catchUnhandled: handleNonCriticalError });

  @computed get marketData()
  {
    return ensureFetchableResource(this._marketData);
  }

  @computed public get marketDatasInfo()
  {
    return getMarketDataInfo(this.marketData);
  }

  @computed public get _marketDatasIndexed()
  {
    return indexMarketData(this.marketData);
  }

  @computed get marketDataPerAreas()
  {
    const marketDataPerArea = indexMultipleArrayItems(this.marketData, data => data.area.id);
    const entries = Array.from(marketDataPerArea.entries()).map(([ areaId, transactionsStats ]) => {
      const { area } = transactionsStats[0]; // Safe
      const { displayName: areaName, administrativeAreaLevel } = area;
      const areaTransactionsStatsPerYear = indexMultipleArrayItems(transactionsStats, data => data.year);
      const years = this.marketDatasInfo.years.map(year => {
        const transactions = areaTransactionsStatsPerYear.get(year);
        if (!transactions)
          return null;
        return avg(transactions.map(({ pricePerSurface }) => pricePerSurface));
      });
      const value = {
        areaId,
        areaName,
        years,
        administrativeAreaLevel,
      } as const;
      return [ areaId, value ] as const;
    });
    return new Map(entries);
  }

  public getAreaLastPricePerSurface(selectedAreaId: IdType)
  {
    const { minYear, maxYear } = this.marketDatasInfo;
    return getAreaLastPricePerSurface({
      minYear,
      maxYear,
      selectedAreaId,
      indexedMarketData: this._marketDatasIndexed,
    });
  }

  public getMarketDatasIndexed(date: Date, areaId: IdType)
  {
    return getMarketDataIndexed({ date, areaId, indexedMarketData: this._marketDatasIndexed });
  }

  public getSelectedAreaPricePerSurface(date: Date, areaId: IdType)
  {
    return getSelectedAreaPricePerSurface({ date, areaId, indexedMarketData: this._marketDatasIndexed });
  }

  public getPricePerSurfaceActualized(transactionDate: number/** timestamp */, areaId: IdType, pricePerSurface: number)
  {
    return getPricePerSurfaceActualized({
      transactionDate,
      areaId,
      pricePerSurface,
      indexedMarketData: this._marketDatasIndexed,
      minYear: this.marketDatasInfo.minYear,
      maxYear: this.marketDatasInfo.maxYear,
    });
  }

  public valuationPdf = new Fetchable(GetPropertyUserValuationPDF, { catchUnhandled: handleNonCriticalError });

  public async setValuationPdf(data: SetPropertyValuationPDFArgs)
  {
    const res = await SetPropertyValuationPDF({
      propertyId: this.property.id,
      userId: this.user.id,
      data,
    });
    this.valuationPdf.setResult(res);
  }

  public async overwriteLastPropertyValuation(id: IdType)
  {
    await OverwriteLastPropertyValuation(id);
  }

  public async requestValuationPdfReview(id: IdType)
  {
    const res = await RequestValuationPDFValidation({ id });
    this.valuationPdf.setResult(res);
  }

  public async forceValuationPdfValidation(id: IdType)
  {
    const res = await ForceValuationPDFValidation({ id });
    this.valuationPdf.setResult(res);
  }

  public async refresh(propertySlug: string, userId: IdType | null)
  {
    googleMapsAPI.init(__GOOGLE_MAPS_API_KEY__);
    const property = await this._property.ensureSuccessReload({
      slug: propertySlug,
      language: localesStore.currentLocale,
    });

    if (userId)
      await this._user.ensureSuccessReload(userId);
    else
      this._user.setResult(sessionStore.mybUser!); // Safe assertion

    const valuationPdf = await this.valuationPdf.ensureSuccessReload({
      slug: propertySlug,
      userId: this.user.id,
    });

    const savedFilters = valuationPdf?.data.transactionsFilters;
    const { surface, addrStreetNumberOdd, deltaAddressInfo } = property;
    const surfaceOffset = surface * 0.4;
    const defaultSurfaceMin = Math.round(surface - surfaceOffset);
    const defaultSurfaceMax = Math.round(surface + surfaceOffset);
    const defaultDateMin = startOfYear({ date: Date.now(), shift: -6 });
    const defaultSameStreet = true;
    const defaultStreetNumberOdd = undefined;
    this._transactionsFilters = {
      surfaceMin: savedFilters?.surfaceMin ?? (deltaAddressInfo?.surfaceMin ? Math.round(deltaAddressInfo.surfaceMin) : defaultSurfaceMin),
      surfaceMax: savedFilters?.surfaceMax ?? (deltaAddressInfo?.surfaceMax ? Math.round(deltaAddressInfo.surfaceMax) : defaultSurfaceMax),
      radius: savedFilters?.radius ?? deltaAddressInfo?.radius ?? 300,
      sameStreet: savedFilters?.sameStreet ?? (!deltaAddressInfo ? defaultSameStreet : deltaAddressInfo.sameStreet !== null),
      streetNumberOdd: savedFilters ? (
        savedFilters.streetNumberOdd ?? undefined
      ) : (
        deltaAddressInfo?.sameStreet?.sameSide ? addrStreetNumberOdd : defaultStreetNumberOdd
      ),
      dateMin: savedFilters?.dateMin ?? (
        deltaAddressInfo?.fromYear
          ? createDate({ year: deltaAddressInfo.fromYear, month: 1, day: 1 }).valueOf()
          : defaultDateMin.valueOf()
        ),
      dateMax: savedFilters?.dateMax,
      pricePerSurfaceMin: savedFilters?.pricePerSurfaceMin,
      pricePerSurfaceMax: savedFilters?.pricePerSurfaceMax,
    };

    await Promise.all([
      this._marketData.ensureSuccessReload({
        geoAreaIds: property.geoAreas_valuation.map((geoArea) => geoArea.id),
        language: localesStore.currentLocale,
      }),
      this.transactions.ensureSuccessReload(),
      this._agencies.ensureSuccessReload(),
    ]);

    // Add potentially missing transactions
    if (valuationPdf)
    {
      assert(this.transactions.result);
      for (const transaction of valuationPdf.transactions)
      {
        const transactionExists = this.transactions.result.data.some(({ id }) => id === transaction.id);
        if (transactionExists)
          continue;
        const { data, count } = this.transactions.result;
        const newCount = count + 1;
        const newData = [ transaction, ...data ];
        this.transactions.setResult({ count: newCount, data: newData });
      }
    }
  }

  private _improvedDescriptions = new Fetchable(
    Valuation_GetImprovedPropertyDescriptions,
    { catchUnhandled: handleNonCriticalError },
  );

  public get improvedDescriptionsFetchable()
  {
    return this._improvedDescriptions;
  }

  @computed get improvedDescriptions()
  {
    return ensureFetchableResource(this._improvedDescriptions);
  }

  private _fixedDescription = new Fetchable(
    Valuation_GetFixedPropertyDescription,
    { catchUnhandled: handleNonCriticalError },
  );

  public get fixedDescriptionFetchable()
  {
    return this._fixedDescription;
  }

  @computed get fixedDescription()
  {
    return ensureFetchableResource(this._fixedDescription);
  }

  private _analyzedDescription = new Fetchable(
    Valuation_GetAnalyzedPropertyDescription,
    { catchUnhandled: handleNonCriticalError },
  );

  public get analyzedDescriptionFetchable()
  {
    return this._analyzedDescription;
  }

  @computed get analyzedDescription()
  {
    return ensureFetchableResource(this._analyzedDescription);
  }

  private _translatedDescription = new Fetchable(
    Valuation_GetTranslatedPropertyDescription,
    { catchUnhandled: handleNonCriticalError },
  );

  public get translatedDescriptionFetchable()
  {
    return this._translatedDescription;
  }

  @computed get translatedDescription()
  {
    return ensureFetchableResource(this._translatedDescription);
  }
}

export default new ValuationStore();
